<template>
  <login-layout
    page-class="forgot-password"
    pageTitle="Forgot Password"
    icon="user"
  >
    <div class="form-block-wrapper">
      <VeeForm
        @submit="submitForm"
        ref="editConnectorForm"
        v-slot="{ errors }"
        class="form"
        novalidate
      >
        <transition name="fade">
          <div class="server-error error-message" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </transition>
        <div class="form-group input">
          <Field
            name="email"
            as="input"
            type="email"
            rules="required|email"
            placeholder="Email"
            :disabled="submitting"
          />
          <span class="error-message" v-if="errors.email">
            {{ errors.email }}
          </span>
        </div>
        <div class="form-group submit">
          <button class="btn primary" type="submit">Recover Password</button>
        </div>
        <div class="form-group">
          <router-link to="/login" class="link">
            Have an account? <span>Log in</span>
          </router-link>
        </div>
      </VeeForm>
    </div>
  </login-layout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout";
import { mapActions, mapState } from "vuex";
export default {
  name: "forgot-password",
  computed: {
    ...mapState("auth", ["errorMessage"])
  },
  methods: {
    ...mapActions("auth", ["passwordForgot"]),
    submitForm(values) {
      this.$store.commit("auth/setErrorMessage", null, { root: true });
      if (values) {
        this.passwordForgot(values.email);
      }
    }
  },
  components: {
    LoginLayout
  }
};
</script>
